// Imports for external libraries go here.
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.x

import { Label, Text, Types } from '@marriott/mi-ui-library';
import { PointsCard } from '../../molecules/PointsCard';
import { StyledPointsEarned } from './PointsEarned.styles';
import { PointsEarnedProps, PointsListProps } from './PointsEarned.types';
import { shouldCardDisplay } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';

export const PointsEarnedConfig = {
  emptyLabel: 'PointsSection',
  isEmpty: () => true,
  resourceType: `/components/content/PointsSection`,
};

export const PointsEarnedComp = (props: PointsEarnedProps) => {
  const {
    noOfPoints,
    pointsDescription,
    pointDetailsTitle,
    pointsList,
    enablePointDetailsSection,
    cardName,
    cardType,
    customClass,
    column,
    variationType,
    styleclass,
  } = props;
  const haslist = enablePointDetailsSection === 'true' ? true : false;
  const { cobrandData } = useStore(store => store);

  return (
    <StyledPointsEarned
      data-component-name="o-cobrand-points-section"
      data-testid="points-section"
      className={clsx(
        customClass,
        styleclass,
        'd-md-block',
        shouldCardDisplay(cardType, cobrandData.activeTabValue) ? 'd-block' : 'd-none'
      )}
      data-cardtype={cardType}
    >
      {cardName ? <span className="sr-only">{cardName}</span> : null}
      <div
        className={clsx(
          'row',
          variationType === 'default' ? 'justify-content-center' : 'justify-content-center justify-content-lg-start'
        )}
      >
        <div
          className={clsx(
            column === '2' ? 'col-12 col-lg-10' : 'col-12',
            'points-earned px-2',
            variationType === 'default' ? 'col-lg-8 col-md-10 col-12 px-md-0 px-5' : ''
          )}
        >
          <PointsCard
            isOnSpend={!haslist}
            number={noOfPoints}
            content={pointsDescription}
            column={column}
            cardType={cardType}
            customClass={customClass}
            variationType={variationType}
          />
          {haslist && (
            <div
              className={clsx(
                column === '2' ? 'px-lg-5 pt-lg-4 pb-lg-2' : 'pb-lg-0',
                'points-earned-property d-flex flex-column pt-2 pr-2 pt-md-3 px-md-3 pb-0 pl-2',
                `list-${customClass}`
              )}
              style={
                cobrandData.activeTabValue &&
                cobrandData.activeTabValue === 'business' &&
                shouldCardDisplay(cardType, cobrandData.activeTabValue)
                  ? { minHeight: 0 }
                  : {}
              }
            >
              {pointDetailsTitle && (
                <Label
                  labelText={pointDetailsTitle}
                  size={Types.size.extraSmall}
                  variation={Types.TypographyColorVariations.Alternate}
                  customClass={clsx('mb-lg-3 mb-2 mb-md-3')}
                />
              )}
              {haslist &&
                pointsList &&
                pointsList.map((list: PointsListProps, pointListIndex: number) => (
                  <div
                    key={list.pointDetailsNoOfPoints}
                    className={clsx(
                      'd-flex pb-1 pb-md-0 mb-md-3',
                      cardType === 'business' ? 'flex-row' : 'flex-column flex-md-row',
                      pointListIndex === pointsList.length - 1 ? 'mb-1' : 'mb-2 '
                    )}
                  >
                    <Text
                      copyText={list.pointDetailsNoOfPoints}
                      fontSize={Types.size.medium}
                      element={Types.tags.span}
                      customClass={clsx(
                        'mr-2 mr-md-3 mb-1 mb-md-0',
                        'points-earned-number',
                        cardType === 'business' ? 'text-center' : 'text-md-center text-left'
                      )}
                    />
                    <Text
                      copyText={list.pointDetailsDescription}
                      fontSize={Types.size.extraSmall}
                      element={Types.tags.span}
                      customClass={clsx('align-self-baseline align-self-md-center')}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </StyledPointsEarned>
  );
};

export const PointsEarned = (props: PointsEarnedProps) => {
  return (
    <EditableComponent config={PointsEarnedConfig} {...props}>
      <PointsEarnedComp {...props} />
    </EditableComponent>
  );
};
