// Imports for external libraries go here.

import React from 'react';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import { Points, Text, Types } from '@marriott/mi-ui-library';
import { StyledPointsCard } from './PointsCard.styles';
import { PointsCardProps } from './PointsCard.types';
import { pointsEarnedCharacterCount as charCount } from '../../modules/constants/constants';
import { shouldCardDisplay } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';

export const PointsCard = (props: PointsCardProps) => {
  const { cardType, customClass, styleClass, pointsForCarddetails, variationType } = props;
  const { cobrandData } = useStore(store => store);
  const pointsTextAlignClass = clsx(
    'd-flex align-items-center',
    pointsForCarddetails ? 'pt-md-0 pl-2 pl-md-1' : 'pt-md-1'
  );
  const mobilePointsAlign = clsx(
    'd-flex',
    pointsForCarddetails
      ? 'flex-row py-lg-1 align-items-lg-center'
      : `flex-column flex-md-row ${
          variationType === 'default' ? 'align-items-center pt-md-3 pt-0 pb-md-3' : 'text-center text-md-left'
        }`
  );
  const pointSubtitleAlign = clsx(
    pointsForCarddetails
      ? 'ml-3'
      : (variationType === 'default' ? 'mb-4 mb-md-0 mt-3 text-center text-md-left' : 'mt-2') + ' mt-md-0 ml-0 ml-md-3'
  );

  const getHeadingWrapperClass = () => {
    if (props.isOnSpend) {
      if (props.cardType === 'business') {
        return ' d-flex flex-row text-center text-md-left';
      }
      return mobilePointsAlign;
    } else {
      return 'pt-lg-2 d-block';
    }
  };
  const getSubtitleClass = () => {
    if (props.isOnSpend) {
      return pointSubtitleAlign;
    } else {
      return 'py-2 py-md-3';
    }
  };

  //Below method is to align points earned description with points
  const getContentAlignmentClass = () => {
    if (props.isOnSpend) {
      if (props?.column === '3') {
        if (props?.content?.length < charCount.threeColumn) {
          return 'd-flex align-items-center';
        }
      } else if (props.column === '2') {
        if (props?.content?.length < charCount.twoColumn) {
          return 'd-flex align-items-center';
        }
      }
      return pointsTextAlignClass;
    }
    return '';
  };

  return (
    <StyledPointsCard
      data-component-name="m-cobrand-PointsCard"
      data-testid="cobrand-PointsCard"
      className={clsx(props.isOnSpend ? '' : `points-${customClass}`, styleClass)}
      style={
        cobrandData.activeTabValue &&
        cobrandData.activeTabValue === 'business' &&
        shouldCardDisplay(cardType, cobrandData.activeTabValue)
          ? { minHeight: 0 }
          : {}
      }
    >
      <div className={getHeadingWrapperClass()}>
        <div
          className={clsx(
            props.isOnSpend ? '' : 'mr-lg-0 mr-md-4',
            cardType === 'business' ? 'mt-2 mt-md-0 mr-3 mr-md-0' : ''
          )}
        >
          <Points
            text={props.number}
            variant={pointsForCarddetails ? 'carddetails' : 'default'}
            borderstyle={variationType === 'default' ? 'rounded' : pointsForCarddetails ? 'none' : 'underlined'}
          />
        </div>
        <Text
          customClass={clsx(
            getSubtitleClass(),
            getContentAlignmentClass(),
            cardType === 'business' ? 'text-left text-md-centers' : ''
          )}
          copyText={props.content}
          fontSize={
            pointsForCarddetails
              ? Types.size.small
              : variationType === 'default'
              ? Types.size.medium
              : Types.size.extraSmall
          }
          element={Types.tags.div}
        />
      </div>
    </StyledPointsCard>
  );
};
