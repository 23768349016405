/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useRef, useEffect } from 'react';
import { CardDetailsProps } from './CardDetails.types';
import { StyledCardDetails } from './CardDetails.styles';
import { Heading, Types, Button, Icon } from '@marriott/mi-ui-library';
import { StickyCardSection } from '../StickyCard/StickyCardSection';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import { CardDetailsRight } from './CardDetailsRight/CardDetailsRight';
import { referAFriend, shouldCardDisplay } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';
import { getACDLObject } from '@marriott/mi-headless-utils';

export const CardDetailsConfig = {
  emptyLabel: 'Card Details',
  isEmpty: () => true,
  resourceType: `/components/content/carddetails`,
};

export const CardDetailsComp: FC<CardDetailsProps> = (props: CardDetailsProps) => {
  const {
    showCardArtIcon,
    cardArtIconPath,
    cardArtMessage,
    header,
    cardSummary,
    cardType,
    expandAccordionForMobile,
    appliedCssClassNames,
    componentId,
    linkUrl,
    openInaNewTab,
    linkText,
    clickTrack,
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    impressionTrack,
    impressionCount,
    accordionHeader = 'Overview',
  } = props;
  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };
  const linkType = openInaNewTab ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;
  const [showMobileAccordion, setshowMobileAccordion] = useState(true);
  const cardDetailsRef = useRef<HTMLDivElement>(null);
  const { cobrandData } = useStore(store => store);
  const toggleAccordion = () => {
    setshowMobileAccordion(!showMobileAccordion);
  };

  useEffect(() => {
    setshowMobileAccordion(!expandAccordionForMobile);
    const adobeDataLayer = getACDLObject();
    if (adobeDataLayer) referAFriend(adobeDataLayer, cardDetailsRef);
  }, []);

  return (
    <StyledCardDetails
      data-component-name="o-cobrand-carddetails"
      data-testid="carddetails"
      className={clsx(
        'card-details',
        'mt-2 mt-md-5 pt-3 mb-2 mb-md-5 pb-3',
        shouldCardDisplay(cardType, cobrandData.activeTabValue) ? 'd-block' : 'd-none',
        appliedCssClassNames
      )}
      data-cardtype={cardType}
      id={componentId}
      ref={cardDetailsRef}
    >
      <div className="pt-md-0 card-detail--border t-background-color">
        <div className="card-detail-wrapper pt-lg-4 mt-5 px-lg-4 px-3 pb-5">
          <div className="mr-lg-5">
            <Heading
              titleText={header}
              variation={Types.headingType.title}
              fontSize={Types.size.medium}
              customClass={clsx(
                'text-md-left mb-4 mb-lg-5 pb-lg-2 ml-md-3 pl-xl-4 pl-lg-5 col-md-10 col-lg-12 d-none d-md-block'
              )}
            />
          </div>
          <div className="d-flex flex-column flex-md-row">
            <div className="col-xl-3 col-lg-4 col-md-4 mx-auto col-12 d-flex justify-content-center">
              <StickyCardSection
                {...props}
                variant="vertical"
                hideButtonOnMobile={true}
                cardTitle={cardSummary}
                header={header}
                showCtaButton={true}
                isBadgeDetails={showCardArtIcon}
                badgeIcon={cardArtIconPath && cardArtIconPath.split(':')[1]}
                badgeDetails={cardArtMessage}
              />
            </div>

            <CardDetailsRight {...props} componentId={componentId + '-content'} showAccordion={false} />

            <div className={clsx(`col-12 accordion m-accordion-container d-block d-md-none px-0`)}>
              <Button
                className={clsx([`accordion-button py-3`])}
                ariaExpanded={showMobileAccordion ? false : true}
                callback={() => toggleAccordion()}
              >
                <div className="m-accordion-title">
                  {accordionHeader}
                  <Icon
                    iconClass={clsx('accordion-arrow-icon', showMobileAccordion ? 'icon-arrow-up' : 'icon-arrow-down')}
                  />
                </div>
              </Button>
              <div className={clsx('accordion-collapse', showMobileAccordion ? 'collapse d-none' : '')}>
                <CardDetailsRight {...props} showAccordion={true} />
              </div>
            </div>
            <div className={clsx('pt-4', 'd-flex justify-content-center d-md-none')}>
              <Button
                isLink={true}
                href={linkUrl}
                trackingProperties={trackingProperties}
                target={openInaNewTab === 'true' ? '_blank' : '_self'}
                className={clsx('m-button-m', 'py-2 mb-1 mr-0', 'track-completed')}
                linkType={linkType}
                custom_click_track_value={clickTrackValue}
                buttonCopy={linkText}
                linkAriaLabelOpenNewWindow={openInaNewTab ? 'Open in new tab' : ''}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </StyledCardDetails>
  );
};

export const CardDetails = (props: CardDetailsProps) => {
  return (
    <EditableComponent config={CardDetailsConfig} {...props}>
      <CardDetailsComp {...props} />
    </EditableComponent>
  );
};
