/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import {
  AEMReactCompMap,
  VanilaCardCarouselContainer as CardCarouselContainerMolecule,
  CardVertical,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { FC, lazy, Suspense, useEffect, useRef } from 'react';
import { CardCarouselProps, CarouselContainerProps } from './CobrandCarousel.types';
import { StyledCobrandCarousel } from './CobrandCarousel.styles';
import { setObserver } from '../../modules/utils';
export const CobrandCarouselConfig = {
  emptyLabel: 'CobrandCarousel',
  isEmpty: () => true,
  resourceType: `mi-aem-common-spa/components/content/cardcarouselcontainer`,
};

export const CobrandCarouselComp: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const {
    headerText,
    subHeaderText,
    ctaLabel,
    ctaLink,
    eyebrow,
    ctaType,
    cardCount,
    openInaNewTab,
    totalNumberOfCards,
    trackingProperties,
    styleclass,
    cqItems,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    variation,
    enableTabletBreakpoint = true,
  } = props;

  let { noOfCards, noOfCardsTablet } = props;
  const cobrandCarouselRef = useRef<HTMLDivElement>(null);

  const isCombo = (): boolean => {
    return variation === 'combo';
  };

  if (isCombo()) {
    noOfCards = 2;
    noOfCardsTablet = 2;
  }

  const mapper = AEMReactCompMap(props?.allowedComponents);

  useEffect(() => {
    setObserver(componentId, cobrandCarouselRef);
  }, []);

  // This function is used to render the component in authoring mode authorCardWrapper
  const authorCardWrapper = (index: number, innerCompStyleClass: string | undefined) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={clsx(innerCompStyleClass)}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const publishCardWrapper = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          if (itemKey.includes('cardvertical')) {
            return (
              <li key={itemKey}>
                <CardVertical {...item} cardVerticalVariations="standard" />;
              </li>
            );
          } else if (itemKey.includes('cardoverview') && noOfCards) {
            item['customClass'] =
              noOfCards === 2 ? 'card-overview-two-cards' : noOfCards === 3 ? 'card-overview-three-cards' : '';
          }
          const itemType = item[':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );

            return (
              <li className="container px-1 d-flex" key={itemKey}>
                <Suspense fallback={<div></div>}>
                  <Component {...item} />
                </Suspense>
              </li>
            );
          }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <StyledCobrandCarousel
      data-testid="cardcarouselcontainer"
      data-component-name="o-cobrand-cardcarouselcontainer"
      ref={cobrandCarouselRef}
    >
      <CardCarouselContainerMolecule
        componentId={componentId}
        subHeaderText={subHeaderText}
        styleclass={styleclass}
        variation={variation}
        eyebrow={eyebrow}
        cardCount={cardCount}
        trackingProperties={trackingProperties}
        ctaLabel={ctaLabel}
        headerText={headerText}
        ctaLink={ctaLink}
        openInaNewTab={openInaNewTab}
        ctaType={ctaType}
        isCombo={isCombo}
        noOfCards={noOfCards}
        noOfCardsTablet={noOfCardsTablet}
        enableTabletBreakpoint={enableTabletBreakpoint}
      >
        {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => authorCardWrapper(i, styleclass))}
        {!isAuthorMode && totalNumberOfCards?.map((cardName: string) => publishCardWrapper(cardName, cqItems))}
      </CardCarouselContainerMolecule>
    </StyledCobrandCarousel>
  );
};

export const CarouselEditableComponent = (props: any) => {
  return (
    <EditableComponent config={CobrandCarouselConfig} {...props}>
      <CobrandCarouselComp {...props} componentName={props?.model.cqType.split('/').pop()} />
    </EditableComponent>
  );
};

export const CobrandCarousel = (props: CarouselContainerProps) => {
  const { model } = props;

  if (model.variation === 'combo' && !model.styleclass?.includes('fullbleed')) {
    model.styleclass += ' fullbleed';
  }
  return (
    <div
      className={clsx(
        [model.styleclass?.includes('inverse') ? 'inverse' : ''],
        [model.styleclass?.includes('fullbleed') ? 'm-container-fullbleed' : ''],
        [model.styleclass?.includes('alternate') ? 'alternate' : '']
      )}
      data-testid="card-carousel"
    >
      <div className={clsx('container', { 'p-0': !model.styleclass?.includes('fullbleed') })}>
        <CarouselEditableComponent {...props} />
      </div>
    </div>
  );
};
