/* eslint-disable react-hooks/exhaustive-deps */
// Imports for external libraries go here.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
import { Modal, Button } from '@marriott/mi-ui-library';
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { InternationalPopupProps } from './InternationalPopup.types';
import { StyledInternationalPopup } from './InternationalPopup.styles';
import { getUserLocation } from '../../modules/utils';
import { getACDLObject } from '@marriott/mi-headless-utils';
import DOMPurify from 'isomorphic-dompurify';

export const PopupConfig = {
  emptyLabel: 'International Popup',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/popup`,
};

export const InternationalPopupComp: FC<InternationalPopupProps> = (props: InternationalPopupProps) => {
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const modalProps = {
    className: props.isAuthorMode ? 'modal-wrapper' : 'd-flex justify-content-center',
    secondaryClassName: 'm-auto',
  };

  const sanitizedNode = DOMPurify.sanitize(props.description, { RETURN_DOM: true }); // RETURN_DOM_FRAGMENT to resolve security issue

  const fragmentString = sanitizedNode.innerHTML; // Get the HTML content of the container

  const checkInternationalUser = function (dataLayer?: unknown) {
    const UserLocation = getUserLocation(dataLayer);
    const countryList = props.countryList;
    if (countryList?.includes('UK')) {
      countryList.push('GB');
    }
    if (typeof UserLocation === 'string' && UserLocation?.length) {
      return countryList.includes(UserLocation);
    }
    return false;
  };

  useEffect(() => {
    const adobeDataLayer = getACDLObject();
    if (adobeDataLayer && checkInternationalUser(adobeDataLayer)) {
      setPopupOpenState(!popupOpenState);
    }
  }, []);

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
  };

  const actions = [
    <Button
      isLink={true}
      href={props.secondaryCtaLink}
      className="custom-link t-font-m d-flex align-items-center"
      callback={toggleModal}
    >
      {props.secondaryCtaLabel}
    </Button>,

    <Button
      isLink={true}
      // eslint-disable-next-line no-script-url
      href={props.primaryCtaLink ? props.primaryCtaLink : 'javascript:void(0)'}
      className="m-button-m m-button-primary"
      callback={toggleModal}
    >
      {props.primaryCtaLabel}
    </Button>,
  ];
  return (
    <StyledInternationalPopup
      data-component-name="m-cobrand-InternationalPopup"
      data-testid="cobrand-InternationalPopup"
    >
      {(popupOpenState || props.isAuthorMode) && (
        <Modal {...modalProps} show={true}>
          <Modal.Header
            labelText={props.header}
            popupHeaderOnCLoseFunc={toggleModal}
            className="py-4 pl-3 pr-4 px-md-5 pt-md-5"
          />
          <Modal.Body className="m-modal-body mt-0 mt-md-2 px-3 px-md-5 mr-4 mr-md-0 pb-0 pb-md-3 mb-2">
            <div
              className={clsx('m-modal-content mt-2 mt-md-0 mb-0')}
              dangerouslySetInnerHTML={{
                __html: fragmentString,
              }}
            ></div>
          </Modal.Body>
          <Modal.FooterGeneric
            customClass="flex-sm-row flex-column"
            className="modal-footer m-auto pt-0 pb-md-5"
            actions={actions}
          ></Modal.FooterGeneric>
        </Modal>
      )}
    </StyledInternationalPopup>
  );
};
export const InternationalPopup = (props: InternationalPopupProps) => {
  return (
    <EditableComponent config={PopupConfig} {...props}>
      <InternationalPopupComp {...props} />
    </EditableComponent>
  );
};
